import { Brand, Environment } from '@lendi/lala-react';

export const getBaseUrl = (brand: Brand, environment: Environment, withoutSubDomain = false) => {
  if (brand === Brand.Aussie) {
    switch (environment) {
      case Environment.Staging:
        return `https://${withoutSubDomain ? 'www' : 'tools'}.aussie-stg.com.au`;
      case Environment.Preproduction:
        return `https://${withoutSubDomain ? 'www' : 'tools'}.aussie-preprod.com.au`;
      case Environment.Production:
        return `https://${withoutSubDomain ? 'www' : 'tools'}.aussie.com.au`;
      default:
        return `https://${withoutSubDomain ? 'www' : 'tools'}.aussie-dev.com.au`;
    }
  }
  if (brand === Brand.Domain) {
    switch (environment) {
      case Environment.Staging:
        return `https://tools-dlf.lendi-stg.net`;
      case Environment.Preproduction:
        return `https://tools-dlf.lendi-preprod.net`;
      case Environment.Production:
        return `https://tools-dlf.lendi.com.au`;
      default:
        return `https://tools-dlf.lendi-dev.net`;
    }
  }
  switch (environment) {
    case Environment.Staging:
      return `https://tools.lendi-stg.net`;
    case Environment.Preproduction:
      return `https://tools.lendi-preprod.net`;
    case Environment.Production:
      return `https://tools.lendi.com.au`;
    default:
      return `https://tools.lendi-dev.net`;
  }
};
