import { SessionProvider } from '@lendi/lala-react';
import { SimpleNavbar } from '@lendi/navbar';
import getColour from '@lendi-ui/commons/colours';
import ThemeProvider from '@lendi-ui/theme';

import { ToolCard } from './components';
import { getBrand, getBrandTools, getEnv, getSession, getBaseUrl } from './utils';
import { Heading } from '@lendi-ui/typography';

export default function App() {
  const brand = getBrand();
  const env = getEnv();
  const session = getSession(brand, env);
  const baseUrl = getBaseUrl(brand, env);
  const tools = getBrandTools(brand);

  return (
    <SessionProvider session={session}>
      <ThemeProvider name={brand}>
        <main className="min-h-screen" style={{ backgroundColor: getColour('background', 'page') }}>
          <SimpleNavbar
            homeURL={baseUrl}
            showBookanAppointmentCta={false}
            showContinueAction={false}
            showTalkToExpertCta={false}
            onException={console.error}
          />
          <div className="mx-auto py-9 px-4 flex w-full max-w-[1200px] flex-col gap-6 items-center sm:items-start">
            {tools.map(({ key, category, items }) => (
              <>
                <Heading className="!font-bold" size="md" key={key}>
                  {category}
                </Heading>
                <div className="flex flex-col sm:flex-row gap-6 items-center self-stretch flex-wrap">
                  {items.map(tool => (
                    <ToolCard key={tool.key} tool={tool} />
                  ))}
                </div>
              </>
            ))}
          </div>
        </main>
      </ThemeProvider>
    </SessionProvider>
  );
}
